import { Formik } from "formik";
import React from "react";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";
import { useToasts } from 'react-toast-notifications'

import FormTextInput from "../../Components/Forms/FormTextInput";
import PMButton from "../../Components/PMButton";
import I18n from "../../I18n";
import Colors from "../../Theme/Colors";
import Fonts from "../../Theme/Fonts";
import Images from "../../Theme/Images";
import Metrics from "../../Theme/Metrics";
import AppConfig from "../../Config/AppConfig";
import Countly from "../../Analytics/Countly";

const reportCodeValid = (url) => {
  return new Promise(function(resolve, reject) {
    fetch(url, { method: 'HEAD' })
    .then(res => {
        if (res.ok) {
          resolve(true);
        } else {
          resolve(res.status);
        }
    }).catch(err => {
      resolve(false)
    });
  });
}

const timeout = (ms) => {
  return new Promise(function(resolve, reject) {
    setTimeout(function(){ resolve() }, ms);
  });
}

const validationSchema = () =>
  Yup.object().shape({
    reportCode: Yup.string()
      .required(I18n.t("sharedReportForm.reportCodeRequired"))
  });

const useStyles = createUseStyles({
  wrapper: {},
  appLogoContainer: {
    textAlign: "center",
    paddingLeft: Metrics.doubleBaseMargin,
    paddingRight: Metrics.doubleBaseMargin,
  },
  appLogo: {
    maxWidth: 300,
    maxHeight: 180,
    marginTop: Metrics.doubleSection,
    marginBottom: Metrics.doubleSection,
  },
  intro: {
    ...Fonts.style.condensedBold,
    color: Colors.main.paragraph,
    marginLeft: Metrics.baseMargin,
    marginRight: Metrics.baseMargin,
    textAlign: "center",
  },
  input: {
    marginBottom: Metrics.baseMargin,
  },
  description: {
    marginTop: Metrics.section,
    marginBottom: Metrics.section,
    textAlign: "center",
  },
  notesText: {
    marginTop: Metrics.doubleSection
  },
  notesList: {
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin
  },
  notesListEntry: {
    marginBottom: Metrics.baseMargin
  }
});

const SharedReportForm = ({
  reportCode = ''
}) => {
  const classes = useStyles();
  const { addToast } = useToasts()

  const [loadingShow, setLoadingShow] = React.useState(false);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [codeInvalid, setCodeInvalid] = React.useState(false);

  const tryPerformDownload = async ({reportCode, download}) => {
    if (loadingShow || loadingDownload) {
      addToast(I18n.t("sharedReportForm.alreadyDownloading"), {
        appearance: 'warning',
        autoDismiss: true,
      })
      return null;
    }
    const reportUrl = '/show?id=' + reportCode + '&download=' + (download ? '1' : '0');
    if (download === true) {
      setLoadingDownload(true);
    } else {
      setLoadingShow(true);
    }
    const [codeValidOrStatuscode] = await Promise.all([reportCodeValid(reportUrl), timeout(1800)])

    if (codeValidOrStatuscode === true) {
      addToast(I18n.t("sharedReportForm.downloadStarted"), {
        appearance: 'success',
        autoDismiss: true,
      })

      if (AppConfig.tracking === true) {
        console.log('Tracking: successfull download');
        Countly.add_event({'key': 'downloadSuccess', 'count': 1, 'segmentation': {'branding': AppConfig.brand}});
      }

      window.location = reportUrl;
    } else {
      addToast(I18n.t("sharedReportForm.codeInvalid"), {
        appearance: 'warning',
        autoDismiss: true,
      })

      if (AppConfig.tracking === true) {
        console.log('Tracking: invalid or wrong code');
        if (codeValidOrStatuscode === 404) { // not found: code invalid but pattern valid
          Countly.add_event({'key': 'codeInvalid', 'count': 1, 'segmentation': {'branding': AppConfig.brand}});
        } else if (codeValidOrStatuscode === 400) { // bad request: code pattern wrong
          Countly.add_event({'key': 'codeWrong', 'count': 1, 'segmentation': {'branding': AppConfig.brand}});
        } else { // every other error (like status code 500)
          Countly.add_event({'key': 'serverError', 'count': 1, 'segmentation': {'branding': AppConfig.brand, 'statusCode': codeValidOrStatuscode}});
        }
      }

      setCodeInvalid(true)
    }
    setLoadingShow(false);
    setLoadingDownload(false);
  }

  return (
    <Formik
      initialValues={{ reportCode, download: false }}
      validationSchema={validationSchema()}
      onSubmit={tryPerformDownload}
    >
      {({ values, errors, touched, handleChange, submitForm, setFieldValue }) => (
        <form>
          <div className={classes.wrapper}>
            <div className={classes.appLogoContainer}>
              <img className={classes.appLogo} src={Images.appLogo} alt="" />
            </div>
            <h5 className={classes.intro}>
              {I18n.t("sharedReportForm.title")}
            </h5>
            <p className={classes.description}>
              {I18n.t("sharedReportForm.message")}
            </p>
            <FormTextInput
              name="reportCode"
              autoComplete="off"
              type={"text"}
              value={values.reportCode}
              containerStyle={classes.input}
              onChange={(e) => {
                handleChange("reportCode")(e.target.value);
                setCodeInvalid(false)
              }}
              submitOnEnter
              error={codeInvalid ? I18n.t("sharedReportForm.codeInvalid") : touched.reportCode && errors.reportCode}
              placeholder={I18n.t("sharedReportForm.placeholder")}
              disabled={loadingShow || loadingDownload}
            />
            <br />
            <PMButton
              title={I18n.t("sharedReportForm.buttonShow")}
              onClick={() => {
                setFieldValue('download', false)
                submitForm()
              }}
              loading={loadingShow}
            />
            <PMButton
              title={I18n.t("sharedReportForm.buttonDownload")}
              onClick={() => {
                setFieldValue('download', true)
                submitForm()
              }}
              secondary
              loading={loadingDownload}
            />
          </div>
          <p className={classes.notesText}>
            {I18n.t("sharedReportForm.notes.introduction")}
          </p>
          <ul className={classes.notesList}>
            <li className={classes.notesListEntry}>{I18n.t("sharedReportForm.notes.0")}</li>
            <li className={classes.notesListEntry}>{I18n.t("sharedReportForm.notes.1")}</li>
            <li className={classes.notesListEntry}>{I18n.t("sharedReportForm.notes.2")}</li>
          </ul>
        </form>
      )}
    </Formik>
  );
};

export default SharedReportForm;
