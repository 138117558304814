import React from "react";
import { createUseStyles } from "react-jss";

import I18n from "../I18n";
import Colors from "../Theme/Colors";
import Metrics from "../Theme/Metrics";
import AppConfig from "../Config/AppConfig";

const useStyles = createUseStyles({
  position: {
    position: 'fixed',
    bottom: 0,
    width: 600,
  },
  "@media (max-width: 600px)": {
    position: {
      width: '100%',
    }
  },
  banner: {
    position: 'relative',
    left: 0 - Metrics.doubleBaseMargin, // correct fixed position
    backgroundColor: 'rgba(255,255,255,0.85)',
    paddingTop: Metrics.baseMargin / 2,
    paddingBottom: Metrics.baseMargin / 2,
    paddingLeft: Metrics.baseMargin,
    paddingRight: Metrics.baseMargin,
  },
  text: {
    textAlign: "center",
    color: Colors.main.paragraph
  },
  link: {
    color: Colors.main.paragraph
  },
  spacer: {
    height: '5em' // Guested height of cookie banner so it doesnt cover content
  }
});

const PrivacyBanner = () => {
  const classes = useStyles();

  if (AppConfig.tracking === false ||  AppConfig.privacyLink === '') {
    return null;
  }

  return (
    <div>
      <div className={classes.spacer} />
      <div className={classes.position}>
        <div className={classes.banner}>
          <p className={classes.text}>
            {I18n.t("sharedReportForm.privacyHint")}
            <a href={AppConfig.privacyLink} className={classes.link}>{I18n.t("sharedReportForm.privacyLinkText")}</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyBanner;
