import React from "react";
import { createUseStyles } from "react-jss";
import { useFormikContext } from 'formik';

import Fonts from "../../Theme/Fonts";
import InputContainer from "./InputContainer";

const useStyles = createUseStyles({
  input: {
    outline: "none",
    border: "none",
    width: "100%",
    background: "transparent",
    height: 48,
    ...Fonts.style.p,
  },
});

const FormTextInput = ({
  label,
  error,
  containerStyle,
  type,
  value,
  onChange,
  left,
  right,
  placeholder,
  disabled,
  submitOnEnter = false,
  ...props
}) => {
  const classes = useStyles();
  const { submitForm } = useFormikContext();
  return (
    <InputContainer
      containerStyle={containerStyle}
      label={label}
      error={error}
      left={left}
      right={right}
      disabled={disabled}
    >
      <input
        className={classes.input}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={(keyboardEvent) => {
          const {key} = keyboardEvent
          if (key === 'Enter' && submitOnEnter) {
            // Try to submit form if user presses enter
            keyboardEvent.preventDefault()
            submitForm()
          }
        }}
        {...props}
      />
    </InputContainer>
  );
};

export default FormTextInput;
