const type = {
  family: '"Roboto", sans-serif',
  base: '"Roboto", sans-serif',
  bold: '"Roboto", sans-serif',
  condensed: {
    bold: '"Roboto Condensed", sans-serif',
  },
};

const size = {
  h1: 38,
  h2: 34,
  h3: 30,
  h4: 26,
  h5: 20,
  h6: 19,
  input: 18,
  verybig: 30,
  big: 20,
  regular: 17,
  medium: 14,
  small: 12,
  tiny: 8.5,
  contentTabBarLabels: 17,
  cardText: 15,
  headerBar: 17,
  navBar: 24,
};

const style = {
  h1: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h1,
  },
  h2: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h2,
  },
  h3: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h3,
  },
  h4: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h4,
  },
  h5: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h5,
  },
  h6: {
    fontFamily: type.bold,
    fontWeight: 700,
    fontSize: size.h6,
  },
  p: {
    fontFamily: type.base,
    fontSize: size.regular,
  },
  summary: {
    fontFamily: type.base,
    fontSize: size.regular,
    lineHeight: size.regular * 1.6,
  },
  normal: {
    fontFamily: type.base,
    fontSize: size.regular,
  },
  description: {
    fontFamily: type.base,
    fontSize: size.medium,
  },
  bold: {
    fontFamily: type.bold,
    fontWeight: 700,
  },
  condensedBold: {
    fontFamily: type.condensed.bold,
    fontWeight: 700,
  },
};

const Fonts = {
  type,
  size,
  style,
};

export default Fonts;
