import chroma from "chroma-js";
import React from "react";
import { createUseStyles } from "react-jss";

import Colors from "../../Theme/Colors";
import Fonts from "../../Theme/Fonts";
import Metrics from "../../Theme/Metrics";
import mergeClasses from "../../Utils/mergeClasses";

const useStyles = createUseStyles({
  wrapper: {},
  inputContainer: {
    display: "flex",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: Colors.main.border,
    minHeight: 48,
    background: Colors.main.grey6,
    borderRadius: 32,
    padding: "0 16px",
    "&:focus-within": {
      boxShadow: `0 0 0 4px ${chroma(Colors.main.border).alpha(0.4).hex()}`,
    },
  },
  inputContainerError: {
    borderColor: Colors.main.error,
    "&:focus-within": {
      boxShadow: `0 0 0 4px ${chroma(Colors.main.error).alpha(0.4).hex()}`,
    },
  },
  inputContainerDisabled: {
    background: Colors.main.grey5,
    borderColor: Colors.main.border,
    boxShadow: "none !important",
  },
  label: {
    ...Fonts.style.bold,
    marginBottom: Metrics.baseMargin,
    textAlign: "left",
    userSelect: "none",
  },
  error: {
    ...Fonts.style.p,
    fontSize: Fonts.size.medium,
    color: Colors.main.error,
    marginTop: Metrics.baseMargin,
    textAlign: "left",
    userSelect: "none",
  },
  left: {
    display: "flex",
    alignItems: "center",
    marginRight: Metrics.baseMargin,
  },
  right: {
    display: "flex",
    alignItems: "center",
    marginLeft: Metrics.baseMargin,
  },
});

const InputContainer = ({
  children,
  containerStyle,
  label,
  error,
  left,
  right,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.wrapper, containerStyle)}>
      {label && <div className={classes.label}>{label}</div>}
      <div
        className={mergeClasses(
          classes.inputContainer,
          error && classes.inputContainerError,
          disabled && classes.inputContainerDisabled
        )}
      >
        {left && <div className={classes.left}>{left}</div>}
        {children}
        {right && <div className={classes.right}>{right}</div>}
      </div>
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default InputContainer;
