import { ToastProvider } from 'react-toast-notifications';
import { createUseStyles } from "react-jss";

import SharedReport from "./Containers/SharedReport";
import PrivacyBanner from "./Components/PrivacyBanner";
import Colors from "./Theme/Colors";
import Fonts from "./Theme/Fonts";
import Metrics from "./Theme/Metrics";
import AppConfig from "./Config/AppConfig";
import Countly from "./Analytics/Countly";

const useStyles = createUseStyles({
  "@global": {
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },
    body: {
      margin: 0,
      background: Colors.main.appBackground,
      ...Fonts.style.p,
      color: Colors.main.paragraph,
    },
    h1: {
      ...Fonts.style.h1,
    },
    h2: {
      ...Fonts.style.h2,
    },
    h3: {
      ...Fonts.style.h3,
    },
    h4: {
      ...Fonts.style.h4,
    },
    h5: {
      ...Fonts.style.h5,
    },
    h6: {
      ...Fonts.style.h6,
    },
  },
  wrapper: {
    maxWidth: 600,
    minHeight: "100vh",
    margin: "0 auto",
    background: Colors.main.appBackgroundLighter,
    padding: Metrics.doubleBaseMargin,
  },
});

function App() {
  const classes = useStyles();

  if (AppConfig.tracking === true) {
    console.log('Tracking: activating countly');
    Countly.init({
      app_key: AppConfig.trackingKey,
      url: "https://analytics.pathmate.online/",
      require_consent: false,
      storage: 'none',
      debug: AppConfig.debug
    });
    Countly.begin_session();
  }

  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={6000}
    >
      <div className={classes.wrapper}>
        <SharedReport />
        <PrivacyBanner />
      </div>
    </ToastProvider>
  );
}

export default App;
