import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Brand from "./Theme/Brand";

document.title = Brand.strings.title;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
