import React from "react";

import SharedReportForm from "./SharedReportForm";

const parseCodeFromURL = () => {

  const url = new URL(window.location.href);
  
  // use pathname as default value for code
  let code = ''

  // 2. check if search string is given (e.g. https://visite.manoa.app/index?Abcd-efgh-0123)
  const searchString = url.search.replace("?", "");
  if (searchString) {
    code = searchString
  }

  return code
}

const SharedReport = () => {
  const code = parseCodeFromURL()
  return <SharedReportForm reportCode={code} />;
};

export default SharedReport;
