// Brand Colors can be used to quickly adjust Look & Feel of the app.
// None of the Colors is used directly, they are just referenced in appColors and can be fully overwritten there.

import AppConfig from "../Config/AppConfig";

import appLogoPathmate from "../Images/app_logo_pathmate.png";
import appLogoManoa from "../Images/app_logo_manoa.png";
import appLogoSonoa from "../Images/app_logo_sonoa.png";
import appLogoSanitas from "../Images/app_logo_sanitas.png";
import appLogoVeroval from "../Images/app_logo_veroval.png";

const BrandManoa = {
  colors: {
    primary: "#FA7311",
    secondary: "#273040",
    success: "#49ad31",
    warning: "#FAB42A",
    danger: "#CE0000",
    grey1: "#202124",
    grey2: "#7d8188",
    grey3: "#afb2b6",
    grey4: "#e4e6e8",
    grey5: "#f1f2f3",
    grey6: "#f9fafa",
    black: "#000000",
    white: "#FFFFFF",
  },
  strings: {
    title: "Manoa",
  },
  images: {
    appLogo: appLogoManoa,
  },
};

const BrandSonoa = {
  colors: {
    primary: '#5150b2',
    secondary: '#3c3e73',
    success: '#49ad31',
    warning: '#FAB42A',
    danger: '#CE0000',
    grey1: '#27272a',
    grey2: '#7d8188',
    grey3: '#afb2b6',
    grey4: '#e4e6e8',
    grey5: '#f1f2f3',
    grey6: '#f8f9fd',
    black: '#000000',
    white: '#FFFFFF'
  },
  strings: {
    title: "Sonoa",
  },
  images: {
    appLogo: appLogoSonoa,
  },
};

const BrandSanitas = {
  colors: {
    primary: "#5BAC26",
    secondary: "#054435",
    success: "#5BAC26",
    warning: "#EFAC00",
    danger: "#F22C00",
    grey1: "#333333",
    grey2: "#828282",
    grey3: "#B1B1B1",
    grey4: "#E0E0E0",
    grey5: "#FAFAFA",
    black: "#000000",
    white: "#FFFFFF",
  },
  strings: {
    title: "Sanitas",
  },
  images: {
    appLogo: appLogoSanitas,
  },
};

const BrandVeroval = {
  colors: {
    primary: '#0045FF',
    secondary: '#001689',
    success: '#00D8B4',
    warning: '#FF8E00',
    danger: '#FF2954',
    grey1: '#2F2F2F',
    grey2: '#757575',
    grey3: '#757575',
    grey4: '#DDDDDD',
    grey5: '#EBEBEB',
    grey6: '#F5F5F5',
    black: '#000000',
    white: '#FFFFFF'
  },
  strings: {
    title: "Veroval® Lea",
  },
  images: {
    appLogo: appLogoVeroval,
  },
};

const BrandPathmate = {
  colors: {
    primary: "#2AA87D",
    secondary: "#20505B",
    success: "#2AA87D",
    warning: "#EFAC00",
    danger: "#F22C00",
    grey1: "#333333",
    grey2: "#828282",
    grey3: "#B1B1B1",
    grey4: "#E0E0E0",
    grey5: "#F2F2F2",
    grey6: "#FAFAFA",
    black: "#000000",
    white: "#FFFFFF",
  },
  strings: {
    title: "Pathmate",
  },
  images: {
    appLogo: appLogoPathmate,
  },
};

let Brand = BrandPathmate;
switch (AppConfig.brand) {
  case "pathmate":
    Brand = BrandPathmate;
    break;
  case "sanitas":
    Brand = BrandSanitas;
    break;
  case "veroval":
    Brand = BrandVeroval;
    break;
  case "manoa":
    Brand = BrandManoa;
    break;
  case "sonoa":
    Brand = BrandSonoa;
    break;
  default:
    Brand = BrandPathmate;
    break;
}

export default Brand;
