import chroma from "chroma-js";
import React from "react";
import { createUseStyles } from "react-jss";
import { ClassicSpinner } from "react-spinners-kit";

import Colors from "../Theme/Colors";
import Fonts from "../Theme/Fonts";
import Metrics from "../Theme/Metrics";
import mergeClasses from "../Utils/mergeClasses";

const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 48,
    background: Colors.main.primary,
    borderRadius: 32,
    padding: "0 16px",
    marginBottom: 10,
    ...Fonts.style.bold,
    color: Colors.main.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.main.primary,
    userSelect: "none",
    cursor: "pointer",
    outline: "none",
    "&:focus": {
      boxShadow: `0 0 0 4px ${chroma(Colors.main.primary).alpha(0.4).hex()}`,
    },
  },
  wrapperSecondary: {
    background: Colors.main.white,
    color: Colors.main.primary,
    "&:focus": {
      boxShadow: `0 0 0 4px ${chroma(Colors.main.primary).alpha(0.4).hex()}`,
    },
  },
  wrapperLoading: {
    background: Colors.main.grey3,
    boxShadow: "none !important",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: Colors.main.grey2,
  },
  wrapperLoadingSecondary: {
    color: Colors.main.grey3,
    background: Colors.main.white,
  },
  loadingContainer: {
    marginRight: Metrics.doubleBaseMargin,
  }
});

const PMButton = ({ title, onClick, loading, secondary }) => {
  const classes = useStyles();

  return (
    <div
      role="button"
      tabIndex={0}
      className={mergeClasses(
        classes.wrapper,
        secondary && classes.wrapperSecondary,
        loading && classes.wrapperLoading,
        loading && secondary && classes.wrapperLoadingSecondary,
      )}
      onClick={onClick}
    >
      {loading && (
        <div className={classes.loadingContainer}>
          <ClassicSpinner color={!secondary ? Colors.main.white : Colors.main.grey3} size={20} />
        </div>
      )}
      {title}
    </div>
  );
};

export default PMButton;
