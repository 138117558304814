import I18n from "i18n-js";
import AppConfig from "../Config/AppConfig";

// Add different defaultLocale here (default is 'en'). It will be used as a fallback when device locale isn't found in translations
I18n.defaultLocale = "de-DE";

// English language is the main fallback language
I18n.translations = {
  "de-DE": require("./languages/de_DE.json"),
  "de-CH": require("./languages/de_CH.json"),
  "en-GB": require("./languages/en_GB.json"),
  "fr-FR": require("./languages/fr_FR.json"),
  "fr-CH": require("./languages/fr_CH.json"),
  "it-CH": require("./languages/it_CH.json")
};

// Use device language as default language if available as translation, otherwise default language
I18n.locale = AppConfig.locale || I18n.defaultLocale;

console.log(`Current locale: ${I18n.locale}`);
I18n.currentLocale();

export default I18n;
