// https://coolors.co/50514f-f25f5c-ffe066-247ba0-70c1b3
// https://coolors.co/e63946-f1faee-a8dadc-457b9d-1d3557
import Brand from "./Brand";

/**
 * Base colours for the app. Main extends the Brands color object
 * by specifying colors for common UI elements found in the app (eg. headline).
 */
const Main = {
  /**
   * The primary color accent of the app. Used for headlines and
   * buttons in the app, elements where the user should understand as being important.
   */
  primary: Brand.colors.primary,
  /**
   * The secondary color accent of the app. Used as an alternative to primary.
   */
  secondary: Brand.colors.secondary,
  /** Color representing a success or positive state */
  success: Brand.colors.success,
  /**
   * Color representing a potential issue or danger, but
   * doesn't necessitate immediate action from the user.
   */
  warn: Brand.colors.warning,
  /**
   * Color representing danger or a failed or a negative state.
   */
  error: Brand.colors.danger,
  /** Primary background for all app screens */
  appBackground: Brand.colors.grey5,
  /** Lighter variant of the app background */
  appBackgroundLighter: Brand.colors.white,
  /** Background used for all card elements */
  cardBackground: Brand.colors.white,
  /** Common border and divider color */
  border: Brand.colors.grey4,
  /** Common color for headlines (where primary or secondary isn't used) */
  headline: Brand.colors.grey1,
  /** Common color for all text elements */
  paragraph: Brand.colors.grey1,
  /** Common color for text hyperlinks */
  hyperlink: Brand.colors.primary,
  /** Color for secondary text, will appear more subdued */
  subtitle: Brand.colors.grey2,
  /** Color to represent backgrounds in a disabled state */
  disabled: Brand.colors.grey4,
  grey1: Brand.colors.grey1,
  grey2: Brand.colors.grey2,
  grey3: Brand.colors.grey3,
  grey4: Brand.colors.grey4,
  grey5: Brand.colors.grey5,
  grey6: Brand.colors.grey6,
  black: Brand.colors.black,
  white: Brand.colors.white,
};

const Colors = {
  main: Main,
  brand: Brand,
};

export default Colors;
